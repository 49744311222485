<template>
    <nuxt />
</template>

<script setup lang="ts">
import { DefaultApolloClient } from '@vue/apollo-composable';
import { AuthTokenResponse } from '~~/iris/src/api';
import { NestJSApi } from '~~/iris/src/nestjs'
import { getAuthHeaders } from '~~/utils/authorisation';
// import Spinner from 'vue-spinner/src/RiseLoader.vue'


const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()

if (process.client) {
    const nestApi = new NestJSApi({
            prefix: config.public.irisNestUrl || '/iris-estia',
            api: {
                getAuthToken() {
                    return getAuthHeaders(nuxtApp.$auth).then(({ Authorization }): AuthTokenResponse => {
                      if (Authorization) {
                        const [ tokenType, accessToken ] = Authorization.split(' ', 2)
                        return {
                          accessToken,
                          tokenType: tokenType as 'Bearer',
                          expiresIn: 30,
                          message: 'ok',
                          status: true
                        }
                      }
                      throw new Error('no logged in no token')
                    })
                },
            }
        })
        provide(DefaultApolloClient, nestApi.apolloClient())
}    

</script>

<script lang="ts">
    export default {
        middleware: ['auth', 'consultant']
    }
</script>